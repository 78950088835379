import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { statusIsGood } from "lib/middleware/errors";
import { HYDRATE } from "next-redux-wrapper";
import { Blog, BlogState } from "_interfaces/blog";
const initialState:BlogState = {
  posts: [],
  specificPosts: {},
};

export const fetchBlogs = createAsyncThunk<Blog[]>("fetchBlogs", async () => {
  let endPoint = process.env.NEXT_PUBLIC_RENDER_API_HOST;
  const { data } = await axios.get(endPoint + "blog/new");

  return data && data.status && Array.isArray(data.data) ? data.data : [];
});

export const fetchBlogByIds = createAsyncThunk<SearchPayload,number>(
  "fetchBlogByIds",
  async (search:number, thunkAPI) => {
    let endPoint = process.env.NEXT_PUBLIC_RENDER_API_HOST;
    const { status, data } = await axios.get(
      endPoint + "blog/specific?searchString=" + search
    );

    if (statusIsGood(status)) {
      return { search, data: data.data };
    }

    return { search, data: [] };
  }
);

interface SearchPayload{
  search:number,
  data:Blog
}

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action: any) => {
        return {
          ...action.payload.blog,
        };
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.posts = action.payload;
      })
      .addCase(fetchBlogByIds.fulfilled, (state, action) => {
        state.specificPosts[action.payload.search] = action.payload.data;
      });
  },
});

export default blogSlice.reducer;
