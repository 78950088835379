import { createAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import PrivateApi from "_api/PrivateApi";
import { notificationAdd } from "./notifications";
import { comparisons } from "config/app.config";
import { HYDRATE } from "next-redux-wrapper";
import { ComparePayload, Comparer, CompareState } from "_interfaces/compare";
import { AppDispatch, RootState } from "lib/store";
const initialState: CompareState = {
  PROPERTY: {
    results: {},
    total: 0,
    comparables: [],
    sentEmail: false,
  },
  SCHEME: {
    results: {},
    total: 0,
    comparables: [],
    sentEmail: false,
  },
  view: {
    fetched: false,
    visible: false,
    compareHeight: 0,
  },
};

const emptyComparison = {
  PROPERTY: {
    results: {},
    total: 0,
    comparables: [],
    sentEmail: false,
  },
  SCHEME: {
    results: {},
    total: 0,
    comparables: [],
    sentEmail: false,
  },
  structureValidSince: comparisons.structureValidSince,
};

const getStorage = (user) => {
  let compareStore = JSON.parse(localStorage.getItem("comparisons"));
  if (!compareStore) compareStore = { compareStore };
  if (user in compareStore) {
    if (compareStore[user].structureValidSince && Date.now() > compareStore[user].structureValidSince)
      return {
        user,
        store: compareStore[user],
      };
  }

  return {
    user,
    store: Object.assign({}, emptyComparison),
  };
};

const setStorage = (user, comparison) => {
  const compareStore = JSON.parse(localStorage.getItem("comparisons")) || {};

  compareStore[user] = {
    ...comparison,
    modified: Date.now(),
  };

  localStorage.setItem("comparisons", JSON.stringify(compareStore));
};

const addResult = (oldComparison, result) => {
  let newComparison = oldComparison;
  newComparison.results = Object.assign({}, newComparison.results);
  newComparison.comparables = [...newComparison.comparables];
  newComparison.results[result.id] = {
    id: result.id,
    name: result.name || null,
    uri: result.uri || null,
    url: result.url || null,
    availability: result.availability || null,
    thumbnail: result.thumbnail || null,
    address: result.address || null,
    use_class: result.use_class || null,
    tenure: result.tenure || null,
  };
  // if(result.use_class) {
  //     newComparison.results[result.id].use_class = result.use_class;
  //     if(newComparison.comparables.indexOf('use_class') === -1) newComparison.comparables.push('use_class')
  // }
  if ("rent" in result) {
    newComparison.results[result.id].rent = result.rent;
    if (newComparison.comparables.indexOf("rent") === -1) {
      newComparison.comparables.push("rent");
    }
  }
  if ("size" in result) {
    newComparison.results[result.id].size = result.size;
    if (newComparison.comparables.indexOf("size") === -1) newComparison.comparables.push("size");
  }
  newComparison.total++;
  return newComparison;
};

const removeResult = (oldComparison, result) => {
  let newComparison = oldComparison;
  newComparison.results = Object.assign({}, newComparison.results);
  newComparison.comparables = [...newComparison.comparables];
  delete newComparison.results[result.id];

  newComparison.comparables = [];

  Object.values(newComparison.results).forEach((result: any) => {
    if ("rent" in result) {
      if (newComparison.comparables.indexOf("rent") === -1) newComparison.comparables.push("rent");
    }
    if ("size" in result) {
      if (newComparison.comparables.indexOf("size") === -1) newComparison.comparables.push("size");
    }
  });

  newComparison.total--;

  return newComparison;
};

export const fetchComparisons = createAsyncThunk<any, any, { state: RootState }>(
  "fetchComparisons",
  async (_void, { getState }) => {
    let state = getState();
    const { user, store } = getStorage(state.auth.loggedIn ? state.user.id : "public");
    return store;
  }
);

export const addComparisons = createAsyncThunk<
  ComparePayload,
  { comparison: string; result: Comparer },
  { state: RootState }
>("addComparisons", async ({ comparison, result }, { getState }) => {
  let state = getState();
  const { user, store } = getStorage(state.auth.loggedIn ? state.user.id : "public");
  const newComparison = addResult(Object.assign({}, state.compare[comparison]), result);
  newComparison.sentEmail = false;

  store[comparison] = newComparison;
  setStorage(user, store);
  return { comparison, newComparison };
});

export const removeComparisons = createAsyncThunk<
  ComparePayload,
  { comparison: string; resultId: string },
  { state: RootState }
>("removeComparisons", async ({ comparison, resultId }, { getState }) => {
  let state = getState();
  const { user, store } = getStorage(state.auth.loggedIn ? state.user.id : "public");

  const resultToRemove = state.compare[comparison].results[resultId];
  const newComparison = removeResult(Object.assign({}, state.compare[comparison]), resultToRemove);
  newComparison.sentEmail = false;

  store[comparison] = newComparison;

  setStorage(user, store);

  return { comparison, newComparison };
});

export const clearComparisons = createAsyncThunk<ComparePayload, string, { state: RootState }>(
  "clearComparisons",
  async (comparison, { getState }) => {
    let state = getState();
    let { user, store } = getStorage(state.auth.loggedIn ? state.user.id : "public");

    const newComparison = emptyComparison[comparison];

    store = newComparison;

    setStorage(user, store);

    return { comparison, newComparison };
  }
);

// export const sendComparisonEmail = createAsyncThunk<{
//   comparison:string,
//   mailSent:boolean
// },
// string,
// {
//   state:RootState,
//   dispatch:AppDispatch
// }
// >(
//   "sendComparisonEmail",
//   async (comparison, { getState, dispatch }) => {
//     let state = getState();
//     const results = Object.values(state.compare[comparison]).map((result:any) => ({
//       size_ft_max: result.size,
//       name: result.name,
//       uri: result.uri,
//     }));
//     let user_id = state.user.id;
//     let payload = {
//       category: comparison === "SCHEME" ? "scheme" : "property",
//       function: "comparison",
//       subject: `${
//         comparison === "SCHEME" ? "Scheme" : "Property"
//       } Comparison from ${process.env.NEXT_PUBLIC_APP_NAME}`,
//       data: results,
//     };

//     const { status, data, statusText } = await PrivateApi.sendMail(
//       user_id,
//       payload
//     );

//     if (status == 200 || 202) {
//       return { comparison, mailSent: true };
//     } else {
//       dispatch(
//         notificationAdd({
//           location: "header",
//           state: "error",
//           messages: ["Something went wrong, please try again shortly."],
//         })
//       );
//     }
//     return { comparison, mailSent: false };
//   }
// );

export const showComparison = createAction<{ visible: boolean }>("showComparison");

export const compareSlice = createSlice({
  name: "compare",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action) => {
        return {
          ...state,
        };
      })
      .addCase(fetchComparisons.pending, (state, action) => {
        state.view.fetched = false;
      })
      .addCase(fetchComparisons.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          view: {
            ...state.view,
            fetched: true,
          },
        };
      })
      .addCase(addComparisons.fulfilled, (state, action) => {
        return {
          ...state,
          [action.payload.comparison]: action.payload.newComparison,
        };
      })
      .addCase(removeComparisons.fulfilled, (state, action) => {
        return {
          ...state,
          [action.payload.comparison]: action.payload.newComparison,
        };
      })
      .addCase(clearComparisons.fulfilled, (state, action) => {
        return {
          ...state,
          [action.payload.comparison]: action.payload.newComparison,
        };
      })
      // .addCase(sendComparisonEmail.fulfilled, (state, action) => {
      //   return {
      //     ...state,
      //     [action.payload.comparison]:{
      //       ...state[action.payload.comparison],
      //       sentEmail:action.payload.mailSent
      //     }
      //   }
      // })
      .addCase(showComparison, (state, action) => {
        return {
          ...state,
          view: {
            ...state.view,
            visible: action.payload.visible,
          },
        };
      });
  },
});

export default compareSlice.reducer;
