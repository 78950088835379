import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { createWrapper } from "next-redux-wrapper";
import notifications from "../_slices/notifications";
import blog from "../_slices/blog";
import auth from "../_slices/auth";
import user from "../_slices/user";
import compare from "../_slices/compare";
import modal from "../_slices/modal";
import view from "../_slices/view";

import { configureStore } from "@reduxjs/toolkit";
import theme from "_slices/theme";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

// const reducer = (state, action) => {
//   // Managing hydation of state when necessary
//   if (action.type === HYDRATE) {
//     console.log('Hydration is happening');
//     // We are taking control of the merging as double hydrations can occur in some cases.
//     //
//     // https://github.com/kirill-konshin/next-redux-wrapper#app-and-getserversideprops-or-getstaticprops-at-page-level

//     // Reducers are split between server side and client side. Client side ones may use a reconciler to
//     // merge the current client state with the recieved state from the response so that we cn persist auth,
//     // users and others. Serverside reducers will always use the recieved state as the truth and will not merge.

//     const clientsideReducers = [
//       "blog",
//       "user",
//       "auth",
//       "compare",
//       "theme",
//       "notifications",
//     ];
//     const newState = {};
//     let stateDelta, correctReducer;

//     // Iterate all reducers
//     for (const newReducer in state) {
//       if (newReducer in rootReconciler) {
//         // If a reconciler exists then use that to solve the merging
//         newState[newReducer] = rootReconciler[newReducer](
//           state[newReducer],
//           action.payload[newReducer]
//         );
//       } else if (clientsideReducers.indexOf(newReducer) > -1) {
//         // If it's a client side reducer then merge with the client state taking priority.
//         correctReducer = state[newReducer];
//         newState[newReducer] = action.payload[newReducer];
//         try {
//           stateDelta = diff(newState[newReducer], correctReducer);
//           patch(newState[newReducer], stateDelta);
//         } catch {
//           newState[newReducer] = correctReducer;
//         }
//       } else {
//         // Otherwise it is a serveside reducer and we simply take the state as true
//         newState[newReducer] = action.payload[newReducer];
//       }
//     }

//     return newState;
//   } else {
//     return rootReducer(state, action);
//   }
// };

// Create the store with middleware to create observables from redux actions so that rxjs can get involved

//added toolkit - currently merging old store with slices

const store = configureStore({
  reducer: combineReducers({
    auth,
    blog,
    compare,
    notifications,
    user,
    theme,
    modal,
    view,
  }),
  // preloadedState:initialState,
  middleware: [thunkMiddleware],
});

const makeStore = () => {
  return store;
};

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// export an assembled wrapper
export const wrapper = createWrapper(makeStore);
// export const wrapper = (context) => createWrapper(makeStore, {debug: false});
